











































































































































































































































































































































































































/* eslint-disable no-mixed-spaces-and-tabs */
import {
    ExchangeRateModel,
    ExchangeRateTypeEnum,
    GroupByProjectionDefinition,
    ProjectionAggregationEnum,
    QueryOperator,
    SortOrder,
    TradeModel,
    TradeTypeEnum,
    TransactionModel,
    WalletModel,
    WalletTransactionModel
} from '@/libs/Api';
import {Component, Ref, Watch} from 'vue-property-decorator';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload, createGrouppedQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';

import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Constants} from '@/libs/constants/constants';
import Page from '@/Page.vue';
import {tradesStore} from "@/libs/trades/+state/store";
import ApiService from '@/libs/core/api-service';
import {cloneDeep, head} from "lodash";
import moment from "moment";
import { walletTransactionsStore } from '@/libs/wallet-transactions/+state/store';
import { exchangeRatesStore } from '@/libs/exchange-rates/+state/store';
import {transactionsStore} from "@/libs/transactions/+state/store";
import {CrudDataStats} from "@/libs/core/+state/models/crud-data-stats";

@Component({
    computed: {
        moment() {
            return moment
        }
    },
    methods: {head},
    components: {
        CrudTable
    }
})
export default class ProductCftTrades extends Page {

    headers = [
        { text: 'Klient', align: 'left', sortable: false, value: 'partyProduct.party.displayName' },
        { text: 'Typ', align: 'left', sortable: false, value: 'type' },
        { text: 'Počet tokenů', align: 'left', sortable: false, value: 'fromAmount' },
        { text: 'Částka', align: 'left', sortable: false, value: 'toAmount' },
        { text: 'Kurz USD', align: 'left', sortable: false, value: 'exchangeRateUsd' },
        { text: 'Kurz CFT', align: 'left', sortable: false, value: 'exchangeRateCft' },
        { text: 'Datum', align: 'left', sortable: false, value: 'time' },
        { text: 'Zadáno uživatelem', align: 'left', sortable: false, value: 'createdBy.displayName' },
        { text: '', align: 'left', sortable: false, value: 'id' },
    ];

    clientId: string | null = null;
    since: string | null = null;
    until: string | null = null;
    showFilters = false;
    productId = Constants.CftProductId;
    isBuy = false;
    partyProductId = null;
    amount = null;
    onlyDeposit = 0;
    usdExchangeRate: ExchangeRateModel = null;
    cftExchangeRate: ExchangeRateModel = null;
	pageUsdExchangeRate: ExchangeRateModel = null;
	pageCftExchangeRate: ExchangeRateModel = null;
    buyDialog = false;
    sellDialog = false;
    deleteDialog = false;
    buyDialogIsValid = false;
    sellDialogIsValid = false;
    saving = false;
    trade: TradeModel;
    wallets: WalletModel[];
    partyWalletCzk: WalletModel = null;
    partyWalletCft: WalletModel = null;
    basicRules = [
        (v: any) => !!v || 'Toto pole je povinné.'
    ];
	customTime = false;
	tradeInitializating = false;
	usdExchangeRateNotLoaded = false;
	cftExchangeRateNotLoaded = false;
	tradeTime: string | null = null;
	tradeMaxTime: string | null = null;

	pageIndex = 1;
	pageSize = 50;

	@Ref() crudTable!: CrudTable;
    @Ref() buyForm!: any;
    @Ref() sellForm!: any;

    @Watch("clientId")
    @Watch("since")
    @Watch("until")
    @Watch("onlyDeposit")
    filtersChanged(): void {
        this.loadData();
    }

	@Watch("pageIndex")
	@Watch("pageSize")
	paginationChanged() {
		this.loadData();
	}

	@Watch("partyProductId")
    partyProductIdChanged(v: string | null, p: string | null) {
        if (v != null && v != p) {
            this.getWallets();
        }
    }

    get newId(): string {
        return Guid.EmptyGuid();
    }

    get items(): TradeModel[] {
        return tradesStore.useGetter(CrudGetter.Data);
    }
    
    get walletSum(): number {
        const trades = tradesStore.useGetter(CrudGetter.Data) as TradeModel[];
        return trades.select(x => x.toAmount).sum();
    }
    
    get computedBoughtShares(): number | null {
        if (this.usdExchangeRate == null || this.cftExchangeRate == null) {
            return null;
        }
        return Number((this.amount / this.usdExchangeRate.rate / this.cftExchangeRate.rate).toFixed(3));
    }
    
    get computedSoldSharesInCzk(): number | null {
        if (this.usdExchangeRate == null || this.cftExchangeRate == null) {
            return null;
        }
        return Number((this.amount * this.cftExchangeRate.rate * this.usdExchangeRate.rate).toFixed(3));
    }

    get grouppedDataDeposit(): any {
        const data = tradesStore.useGetter(CrudGetter.GrouppedData, 'AmountDeposit');
        return data && data?.length > 0 ? data[0] : null;
    }

    get grouppedDataWithdrawal(): any {
        const data = tradesStore.useGetter(CrudGetter.GrouppedData, 'AmountWithdrawal');
        return data && data?.length > 0 ? data[0] : null;
    }

	get currentTotalTokenValueInCzk(): number | null {
		const deposit = tradesStore.useGetter(CrudGetter.GrouppedData, 'AmountDeposit');
		const withdrawal = tradesStore.useGetter(CrudGetter.GrouppedData, 'AmountWithdrawal');
		if (deposit != null && withdrawal != null && this.pageUsdExchangeRate != null && this.pageCftExchangeRate != null) {
			const currentTokens = deposit[0].amount - withdrawal[0].amount;
			return currentTokens * this.pageCftExchangeRate.rate * this.pageUsdExchangeRate.rate;
		}
		return null;
	}

	get isFutureTradeTime(): boolean {
		debugger;
		if (this.tradeTime == null) {
			return false;
		}
		return moment(this.tradeTime).isAfter(moment());
	}

	get totalRows (): number {
		return ({...tradesStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
	}

    mounted(): void {
        this.loadData();
		this.getCurrentUsdRate(true);
		this.getCurrentCftRate(true);
    }

    loadData(): void {
        let filters: CrudQueryPredicate[] = [
            { field: "partyProduct.productId", op: "eq", comparand: Constants.CftProductId }
        ];
	    filters = filters.concat(this.getFilters());
        tradesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TradeModel>([ { field: "time", index: 1, order: "desc" } ], filters, { pageIndex: this.pageIndex, pageSize: this.pageSize }));
        this.loadGrouppedData();
    }

	getFilters(): CrudQueryPredicate[] {
		let filters: CrudQueryPredicate[] = [];
		if (this.clientId) {
			filters.push({ field: "partyProduct.partyId", op: "eq", comparand: this.clientId });
		}
		if (this.since) {
			filters.push({ field: "time", op: "gte", comparand: this.since });
		}
		if (this.until) {
			filters.push({ field: "time", op: "lte", comparand: this.until });
		}
		if (this.onlyDeposit == 1) {
			filters.push({ field: "fromCurrencyId", op: 'eq', comparand: Constants.CurrencyCzkId });
			filters.push({ field: "toCurrencyId", op: 'eq', comparand: Constants.CurrencyCftId });
		}
		else if (this.onlyDeposit == 2) {
			filters.push({ field: "fromCurrencyId", op: 'eq', comparand: Constants.CurrencyCftId });
			filters.push({ field: "toCurrencyId", op: 'eq', comparand: Constants.CurrencyCzkId });
		}
		return filters;
	}
    
    buyClick(): void {
        this.trade = {
            partyProductId: null,
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
            fromAmount: null,
            toAmount: null
        } as TradeModel;
		this.tradeTime = this.trade.time;
        this.partyProductId = null;
        this.amount = 0;
        this.buyDialog = true;
        this.partyWalletCzk = null;
        this.partyWalletCft = null;
		this.customTime = false;
		this.tradeInitializating = true;
		this.tradeMaxTime = moment().format("YYYY-MM-DD 23:59:59");
		this.$nextTick(() => {
			this.tradeInitializating = false;
			this.getCurrentUsdRate();
			this.getCurrentCftRate();
		});
    }
    
    sellClick(): void {
        this.trade = {
            partyProductId: null,
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
            fromAmount: null,
            toAmount: null
        } as TradeModel;
		this.tradeTime = this.trade.time;
        this.partyProductId = null;
        this.amount = 0;
        this.sellDialog = true;
        this.partyWalletCzk = null;
        this.partyWalletCft = null;
		this.customTime = false;
		this.tradeInitializating = true;
		this.tradeMaxTime = moment().format("YYYY-MM-DD 23:59:59");
		this.$nextTick(() => {
			this.tradeInitializating = false;
			this.getCurrentUsdRate();
			this.getCurrentCftRate();
		});
    }
    
    deleteClick(item: TradeModel) {
        this.deleteDialog = true;
        this.trade = item;
    }

    saveUsdExchangeRate(): Promise<ExchangeRateModel> {
        return new Promise<ExchangeRateModel>((resolve, reject) => {
            if (this.usdExchangeRate.id != Guid.EmptyGuid()) {
                resolve(this.usdExchangeRate);
            }
            else {
                exchangeRatesStore.dispatch(CrudAction.Create, { item: {
                    fromDate: this.tradeTime,
                    toDate: this.tradeTime,
                    fromCurrencyId: Constants.CurrencyUsdId,
                    toCurrencyId: Constants.CurrencyCzkId,
                    rate: this.usdExchangeRate.rate,
                    type: ExchangeRateTypeEnum.Currency,
                    isPublic: false
                } as ExchangeRateModel });
                this.subscribe(exchangeRatesStore, CrudReponse.Create).then((e: ExchangeRateModel) => {
                    this.usdExchangeRate = e;
                    resolve(e);
                }).catch((e) => reject(e));
            }
        });
    }

    saveCftExchangeRate(): Promise<ExchangeRateModel> {
        return new Promise<ExchangeRateModel>((resolve, reject) => {
            if (this.cftExchangeRate.id != Guid.EmptyGuid()) {
                resolve(this.cftExchangeRate);
            }
            else {
                exchangeRatesStore.dispatch(CrudAction.Create, { item: {
                    fromDate: this.tradeTime,
                    toDate: this.tradeTime,
                    productId: Constants.CftProductId,
                    rate: this.cftExchangeRate.rate,
                    type: ExchangeRateTypeEnum.Product,
                    isPublic: false
                } as ExchangeRateModel });
                this.subscribe(exchangeRatesStore, CrudReponse.Create).then((e: ExchangeRateModel) => {
                    this.cftExchangeRate = e;
                    resolve(e);
                }).catch((e) => reject(e));
            }
        });
    }

    saveItem(): void {
		this.trade.time = this.tradeTime;
        const e = cloneDeep(this.trade);
        if (e.id == null || e.id == Guid.EmptyGuid()) {
            this.saveUsdExchangeRate().then(() => {
                this.saveCftExchangeRate().then(() => {
                    if (this.buyDialog) {
                        if (this.buyForm.validate()) {
                            e.type = TradeTypeEnum.Buy;
                            e.partyProductId = this.partyProductId;
                            e.fromAmount = this.amount;
                            e.toAmount = Number((this.amount / this.usdExchangeRate.rate / this.cftExchangeRate.rate).toFixed(3));
                            e.fromCurrencyId = Constants.CurrencyCzkId;
                            e.toCurrencyId = Constants.CurrencyCftId;
                            e.tradeConversions = [
                                {
                                    exchangeRateId: this.usdExchangeRate.id,
                                    fromAmount: this.amount,
                                    toAmount: this.amount / this.usdExchangeRate.rate
                                },
                                {
                                    exchangeRateId: this.cftExchangeRate.id,
                                    fromAmount: this.amount / this.usdExchangeRate.rate,
                                    toAmount: this.amount / this.usdExchangeRate.rate / this.cftExchangeRate.rate
                                }
                            ];
                            this.saving = true;
                            tradesStore.dispatch(CrudAction.Create, { item: e });
                            this.subscribe(tradesStore, CrudReponse.Create).then(() => {
                                this.buyDialog = false;
                                this.sellDialog = false;
                                this.saving = false;
                                this.loadData();
                            })
                        }
                    }
                    else if (this.sellDialog) {
                        if (this.sellForm.validate()) {
                            e.type = TradeTypeEnum.Sell;
                            e.partyProductId = this.partyProductId;
                            e.fromAmount = this.amount;
                            e.toAmount = Number((this.amount * this.cftExchangeRate.rate * this.usdExchangeRate.rate).toFixed(3));
                            e.fromCurrencyId = Constants.CurrencyCftId;
                            e.toCurrencyId = Constants.CurrencyCzkId;
                            e.tradeConversions = [
                                {
                                    exchangeRateId: this.cftExchangeRate.id,
                                    fromAmount: this.amount,
                                    toAmount: this.amount * this.cftExchangeRate.rate
                                },
                                {
                                    exchangeRateId: this.usdExchangeRate.id,
                                    fromAmount: this.amount * this.cftExchangeRate.rate,
                                    toAmount: this.amount * this.cftExchangeRate.rate * this.usdExchangeRate.rate
                                }
                            ];
                            this.saving = true;
                            tradesStore.dispatch(CrudAction.Create, { item: e });
                            this.subscribe(tradesStore, CrudReponse.Create).then(() => {
                                this.buyDialog = false;
                                this.sellDialog = false;
                                this.saving = false;
                                this.loadData();
                            });
                        }
                    }
                });
            });
        }
    }
    
    removeItem(): void {
        this.saving = true;
        tradesStore.dispatch(CrudAction.Delete, createCrudQueryPayload<TransactionModel>(undefined, [ { field: "id", op: "eq", comparand: this.trade.id! } ]));
        this.subscribe(tradesStore, CrudReponse.Delete).then(() => {
            this.saving = false;
            this.deleteDialog = false;
            this.loadData();
        }).catch(ex => {
            this.saving = false;
            console.log(ex);
        })
    }
    
    beforeAdd(e: WalletTransactionModel): void {
        this.amount = 0;
        this.partyProductId = null;
    }

    beforeEdit(e: WalletTransactionModel): void {
        this.isBuy = e.amount >= 0;
        this.amount = Math.abs(e.amount);
        this.partyProductId = e.wallet.partyProductId;
    }
    
    getCurrentUsdRate(firstLoad = false): Promise<number | null> {
		return new Promise<number | null>((resolve, reject) => {
			const predicates = [
				{ field: 'fromCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyUsdId },
				{ field: 'toCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
				{ field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Currency },
				{ field: 'isPublic', op: QueryOperator.Eq, comparand: true }
			];
			if (this.customTime) {
				predicates.push({ field: 'fromDate', op: QueryOperator.Lte, comparand: this.tradeTime });
				predicates.push({ field: 'toDate', op: QueryOperator.Gte, comparand: this.tradeTime });
			}
            ApiService.api.getExchangeRateBatch(1, 1, {
                predicates: predicates,
                orderby: [
                    { field: "toDate", index: 1, order: SortOrder.Desc }
                ]
            }).then((e: any) => {
				this.usdExchangeRate = e.data.returnValue.items.firstOrDefault();
				if (this.usdExchangeRate == null) {
					this.usdExchangeRateNotLoaded = true;
					this.usdExchangeRate = {
						id: Guid.EmptyGuid(),
						rate: null
					};
				}
				else {
					this.usdExchangeRateNotLoaded = false;
				}
				if (firstLoad) {
					this.pageUsdExchangeRate = {...this.usdExchangeRate}
				}
				resolve(this.usdExchangeRate.rate ?? null);
            }).catch((e: any) => reject(e));
        });
    }
    
    getCurrentCftRate(firstLoad = false): Promise<number | null> {
        return new Promise<number | null>((resolve, reject) => {
			const predicates = [
				{ field: 'productId', op: QueryOperator.Eq, comparand: Constants.CftProductId },
				{ field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Product },
				{ field: 'isPublic', op: QueryOperator.Eq, comparand: true }
			];
			if (this.customTime) {
				predicates.push({ field: 'fromDate', op: QueryOperator.Lte, comparand: this.tradeTime });
				predicates.push({ field: 'toDate', op: QueryOperator.Gte, comparand: this.tradeTime });
			}
            ApiService.api.getExchangeRateBatch(1, 1, {
                predicates: predicates,
                orderby: [
                    { field: "toDate", index: 1, order: SortOrder.Desc }
                ]
            }).then((e: any) => {
                this.cftExchangeRate = e.data.returnValue.items.firstOrDefault();
				if (this.cftExchangeRate == null) {
					this.cftExchangeRateNotLoaded = true;
					this.cftExchangeRate = {
						id: Guid.EmptyGuid(),
						rate: null
					};
				}
				else {
					this.cftExchangeRateNotLoaded = false;
				}
				if (firstLoad) {
					this.pageCftExchangeRate = {...this.cftExchangeRate}
				}
				resolve(this.cftExchangeRate.rate ?? null);
            }).catch((e: any) => reject(e));
        });
    }
    
    getWallets(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            ApiService.api.getWalletBatch(1, 99, {
                predicates: [
                    { field: 'partyProductId', op: QueryOperator.Eq, comparand: this.partyProductId }
                ]
            }).then((e: any) => {
                this.wallets = e.data.returnValue.items;
                this.partyWalletCzk = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId && x.partyProductId == this.partyProductId);
                this.partyWalletCft = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCftId && x.partyProductId == this.partyProductId);
                resolve(this.wallets);
            }).catch((e: any) => reject(e));
        });
    }
    
    getUsdRateOfTrade(item: TradeModel): number | null {
        return item.tradeConversions.firstOrDefault(x => x.exchangeRate.type == ExchangeRateTypeEnum.Currency)?.exchangeRate.rate ?? null;
    }
    
    getCftRateOfTrade(item: TradeModel): number | null {
        return item.tradeConversions.firstOrDefault(x => x.exchangeRate.type == ExchangeRateTypeEnum.Product)?.exchangeRate.rate ?? null;
    }
    
    loadGrouppedData(): void {
        tradesStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<TradeModel>([
            { field: 'fromCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
            { field: 'toCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCftId }
        ].concat(this.getFilters() as any), [], [
            {
                field: "toAmount",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "amount"
            } as GroupByProjectionDefinition
        ], 'AmountDeposit'));
        tradesStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<TradeModel>([
            { field: 'fromCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCftId },
            { field: 'toCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId }
        ].concat(this.getFilters() as any), [], [
            {
                field: "fromAmount",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "amount"
            } as GroupByProjectionDefinition
        ], 'AmountWithdrawal'));
    }

	tradeTimeChanged(): void {
		if (!this.tradeInitializating) {
			this.customTime = true;
			this.getCurrentUsdRate();
			this.getCurrentCftRate();
		}
	}

}
