import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

import userSettingsRoutes from '@/libs/user-settings/routes';
import authentificationRoutes from '@/libs/auth/routes';
import systemSettingsRoutes from '@/libs/system-settings/router';

import dashboardRoutes from '@/libs/dashboard/routes';
import calendarRoutes from '@/libs/calendar/routes';
import clientRoutes from '@/libs/clients/routes';
import tasksRoutes from '@/libs/tasks/routes';
import cloudRoutes from '@/libs/cloud/routes';
import accountRoutes from '@/libs/account/routes';
import notificationRoutes from '@/libs/notifications/routes';
import creditsRoutes from '@/libs/credits/routes';

Vue.use(VueRouter)

const routes: RouteConfig[] = [
    ...dashboardRoutes,
    ...authentificationRoutes,
    ...systemSettingsRoutes,
    ...calendarRoutes,
    ...clientRoutes,
    ...tasksRoutes,
    ...cloudRoutes,
    ...userSettingsRoutes,
    ...accountRoutes,
    ...notificationRoutes,
    ...creditsRoutes
]

const router = new VueRouter({
    routes
})

export default router
