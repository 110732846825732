import Vue from 'vue'
import App from './App.vue'
import router from './libs/core/router';
import store from './libs/core/+state/store'
import vuetify from './plugins/vuetify'

import { Journaly, PublisherSubscriber } from 'journaly';
import { StreamInvocationMessage } from '@microsoft/signalr'
import './libs/Api'
import './linq';

Vue.config.productionTip = false

declare global {
  const ApiService: any;
  const SignalClientId: string;
  const SignalClientToken: string;
  const SignalHub: any;
  let Journaly: PublisherSubscriber<string>;
  let SaasApiEndpoint: string;
  let ApiAccessToken: string;
}

(window as any).Journaly = Journaly.newJournaly<string>({
  multiple: true,
  hasTopic: true,
}) as PublisherSubscriber<string>;

declare module 'vue/types/vue' {
  interface Vue {
    SignalHub: any
  }
}

import 'vuetify/src/styles/main.sass';

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';
 
Vue.use(VueAwesomeSwiper);

import TextInput from '@/libs/common/components/TextInput.vue';
Vue.component('TextInput', TextInput);

import TextArea from '@/libs/common/components/TextArea.vue';
Vue.component('TextArea', TextArea);

import NSelect from '@/libs/common/components/NSelect.vue';
Vue.component('NSelect', NSelect);

import Slider from '@/libs/common/components/Slider.vue';
Vue.component('Slider', Slider);

import Loader from '@/libs/common/components/Loader.vue';
Vue.component('Loader', Loader);

import Button from '@/libs/common/components/Button.vue';
Vue.component('Button', Button);

import ButtonToggle from '@/libs/common/components/ButtonToggle.vue';
Vue.component('ButtonToggle', ButtonToggle);

import NDialog from '@/libs/common/components/NDialog.vue';
Vue.component('NDialog', NDialog);

import ConfirmDialog from '@/libs/common/components/ConfirmDialog.vue';
Vue.component('ConfirmDialog', ConfirmDialog);

import TextEditor from '@/libs/common/components/TextEditor.vue';
Vue.component('TextEditor', TextEditor);

import DateTimePicker from '@/libs/common/components/DateTimePicker.vue';
Vue.component('DateTimePicker', DateTimePicker);

import DatePicker from '@/libs/common/components/DatePicker.vue';
Vue.component('DatePicker', DatePicker);

import Toast from '@/libs/common/components/Toast.vue';
Vue.component('Toast', Toast);

import Autocomplete from '@/libs/common/components/Autocomplete.vue';
Vue.component('Autocomplete', Autocomplete);

import CrudTable from '@/libs/common/components/CrudTable.vue';
Vue.component('CrudTable', CrudTable);

import ColorPicker from '@/libs/common/components/ColorPicker.vue';
Vue.component('ColorPicker', ColorPicker);

import CodebookTable from '@/libs/common/components-smart/CodebookTable.vue';
Vue.component('CodebookTable', CodebookTable);

import ProductTypeSelect from '@/libs/common/components-smart/ProductTypeSelect.vue';
Vue.component('ProductTypeSelect', ProductTypeSelect);

import ProductSelect from '@/libs/common/components-smart/ProductSelect.vue';
Vue.component('ProductSelect', ProductSelect);

import CodebookSelect from '@/libs/common/components-smart/CodebookSelect.vue';
Vue.component('CodebookSelect', CodebookSelect);

import SearchBox from '@/libs/search/components/SearchBox.vue';
Vue.component('SearchBox', SearchBox);

import PartySelect from '@/libs/common/components-smart/PartySelect.vue';
Vue.component('PartySelect', PartySelect);

import ClientSelect from '@/libs/common/components-smart/ClientSelect.vue';
Vue.component('ClientSelect', ClientSelect);

import ConsultantSelect from '@/libs/common/components-smart/ConsultantSelect.vue';
Vue.component('ConsultantSelect', ConsultantSelect);

import PartyProductSelect from '@/libs/common/components-smart/PartyProductSelect.vue';
Vue.component('PartyProductSelect', PartyProductSelect);

import '@/libs/common/functions/string-format';
import '@/libs/common/filters/format-get';
import '@/libs/common/filters/format-currency';
import '@/libs/common/filters/format-percent';
import '@/libs/common/filters/format-date';
import '@/libs/common/filters/format-number';
import '@/libs/common/filters/format-text';


// Layout styles
import "@/assets/common.scss";
import "@/assets/scrollbar.scss";
import "@/assets/detail.scss";
import "@/assets/icons.scss";
import "@/assets/spacing.scss";
import "@/assets/grid-system.scss";

// Components styles
import "@/assets/components/text-color.scss";
import "@/assets/components/nav.scss";
import "@/assets/components/sidebar.scss";
import "@/assets/components/text-input.scss";
import "@/assets/components/v-textarea.scss";
import "@/assets/components/v-select.scss";
import "@/assets/components/v-dialog.scss";
import "@/assets/components/v-progress.scss";
import "@/assets/components/kanban.scss";
import "@/assets/components/files.scss";
import "@/assets/components/v-card.scss";
import "@/assets/components/v-chip.scss";
import "@/assets/components/v-toolbar.scss";
import "@/assets/components/v-data-table.scss";
import "@/assets/components/crud-table.scss";
import "@/assets/components/v-tabs.scss";
import "@/assets/components/v-btn.scss";
import "@/assets/components/v-btn-toggle.scss";
import "@/assets/components/v-picker.scss";
import "@/assets/components/v-calendar.scss";
import "@/assets/components/v-date-picker.scss";
import "@/assets/components/v-badge.scss";
import "@/assets/components/v-switch.scss";
import "@/assets/components/v-autocomplete.scss";
import "@/assets/components/v-snackbar.scss";
import "@/assets/components/swiper.scss";
import "@/assets/components/loader.scss";
import "@/assets/components/searchbox.scss";
import "@/assets/components/filemanager.scss";
import "@/assets/components/avatar.scss";

// Pages styles
import "@/assets/pages/sign-in.scss";
import "@/assets/pages/dashboard.scss";
import "@/assets/pages/client-comments.scss";
import "@/assets/pages/client-products.scss";

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
