













import { ProductTypeModel } from '@/libs/Api';
import { Component, Prop, PropSync, VModel, Vue, Watch } from 'vue-property-decorator';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { productTypesStore } from '@/libs/products/+state/store';
import {ProductTypeId} from "@/libs/products/product-types";

@Component({ })
export default class ProductTypeSelect extends Vue {

    @Prop({ default: "Typ produktu" }) label!: string;
    @VModel() innerValue!: string;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: true }) investment!: boolean;

    get items(): ProductTypeModel[] {
        if (this.investment) {
            return (productTypesStore.useGetter(CrudGetter.Data) as ProductTypeModel[]);
        }
        else {
            return (productTypesStore.useGetter(CrudGetter.Data) as ProductTypeModel[]).filter(x => x.id != ProductTypeId.InvestmentTypeId && x.id != ProductTypeId.CreditTypeId);
        }
    }

}
