




























































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { AccountTypeEnum, ApplicationUserModel, PartyModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { partiesStore } from '@/libs/parties/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { applicationUserStore } from '@/libs/user-settings/+state/store';
import AccountTypeSelect from '@/libs/common/components-smart/AccountTypeSelect.vue';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';

@Component({
    components: { AccountTypeSelect  }
})
export default class ClientAddDialog extends Vue {

  show = false;
  item: PartyModel | null = null;
  valid = false;
  basicRules = [
    (v: any) => !!v || 'Toto pole je povinné.'
  ];
  emailOrNullRules = [
    (v: any) => {
      debugger;
      if (!v) {
        return true;
      }
      else {
        //eslint-disable-next-line
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (regex.test(v)) {
          return true;
        }
        else {
          return 'Zadejte platný e-mail';
        }
      }
    }
  ];
  emailExists = false;
  
  @Watch("show")
  showChanged(v: boolean) {
    if (!v)
      this.close();
  }

  @Prop({ default: true }) redirect!: boolean;
  @Ref() form!: any;

  get saving(): boolean {
    return partiesStore.useGetter(CrudGetter.Saving);
  }

  get user() : ApplicationUserModel {
    return applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
  }

  get canEditClientCode () {
    return this.user.accountType == AccountTypeEnum.Admin || this.user.accountType == AccountTypeEnum.Manager;
  }

  open () {
      const userModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
      this.show = true;
      this.item = {
          id: Guid.EmptyGuid(),
          firstName: "",
          lastName: "",
          displayName: "",
          email: "",
          phoneNumber: "",
          clientCode: "",
          accountType: AccountTypeEnum.Client,
          ownerId: userModel.accountType == "Consultant" ? userModel.partyId : null
      } as PartyModel;
  }

  save () {
    if (this.form.validate()) {
      const self = this;
      if (this.item!.email != null && this.item!.email.length > 0) {
        partiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyModel>(undefined, [
          { field: "email", op: "eq", comparand: this.item!.email }
        ], undefined, false, "VerifyExistingEmail"));
        const subscribtion = this.$store.subscribe((mutation, _state) => {
          if (mutation.type === partiesStore.getActionName(CrudResponseAction.GetAllSuccess)) {
            debugger;
              subscribtion();
              if (mutation.payload.data.length > 0) {
                this.emailExists = true;
              }
              else {
                self.createClient();
              }
          }
          else if (mutation.type === partiesStore.getActionName(CrudResponseAction.GetAllNoop)) {
              subscribtion();
          }
        });
      }
      else {
        this.createClient();
      }
    }
  }

  createClient () {
    const self = this;
    partiesStore.dispatch(CrudAction.Create, { item: this.item });
    const subscribtion = this.$store.subscribe((mutation, _state) => {
      if (mutation.type === partiesStore.getActionName(CrudResponseAction.CreateSuccess)) {
        subscribtion();
        self.close();
        if (self.redirect)
          self.$router.push({ name: 'ClientGeneral', params: { id: mutation.payload.item.id } });
        else
          self.$emit("client-created", mutation.payload.item.id);
      }
      else if (mutation.type === partiesStore.getActionName(CrudResponseAction.CreateFailed)) {
          subscribtion();
      }
    });
  }

  close () {
    this.show = false;
  }
    
}
