import { CrudStore } from '@/libs/core/+state/models/crud-store';
import { CrudDataQuery, CrudEntity, CrudService } from '@/libs/core/+state/models/crud-service';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Module } from 'vuex';
import store from '@/libs/core/+state/store';
import { CreateCrudStoreResult } from '@/libs/core/+state/models/create-crud-store-result';
import { createStoreKey } from '@/libs/core/+state/functions/create-store-key';
import { BusinessModule } from '@/libs/core/models/business-module';
import { createStoreGetter } from '@/libs/core/+state/functions/create-store-getter';
import { createStoreAction } from '@/libs/core/+state/functions/create-store-action';
import { compare, applyPatch, ReplaceOperation } from 'fast-json-patch';
import { cloneDeep } from 'lodash';
import { patchStoreData, updateStoreData } from '@/libs/core/+state/functions/patch-store-data';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { Operation } from 'fast-json-patch';
import { AggregationEnum, FileDownloadModel, GrouppedQueryModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { CrudDataStats } from './models/crud-data-stats';

export function registerCrudStore<T extends CrudEntity, TDetail extends CrudEntity>(storeName: BusinessModule, crudServiceInstance: CrudService<T, TDetail>, storeModule: Omit<Module<any, any>, 'namespaced' | 'modules'> = {}): CreateCrudStoreResult<T> {
    const setPending = (value: boolean, state: any, key = '') => {
        state.pending = {
            ...state.pending,
            [key]: value
        }
    }
    const setSaving = (value: boolean, state: any, key = '') => {
        state.saving = {
            ...state.saving,
            [key]: value
        }
    }
    const setDeleting = (value: boolean, state: any, key = '') => {
        state.deleting = {
            ...state.deleting,
            [key]: value
        }
    }
    const setDownloading = (value: boolean, state: any, key = '') => {
        state.downloading = {
            ...state.downloading,
            [key]: value
        }
    }

    const crudStore: CrudStore<T, TDetail> = {
        namespaced: true,
        state: {
            dataQuery: {},
            data: {
                '': []
            },
            dataStats: {},
            detail: {},
            pending: {},
            saving: {},
            deleting: {},
            downloading: {},
            count: {},
            avg: {},
            sum: {},
            grouppedData: {},
            crudStoreEcho: {
                data: {},
                detail: {},
                grouppedData: {},
                sum: {}
            },
            error: undefined,
            ...storeModule.state
        },
        actions: {
            [CrudAction.GetAll]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    commit(CrudAction.GetAll, payload);

                    let queries: CrudDataQuery<T>[];
                    if (payload.query === 'same') {
                        queries = Object.keys(state.dataQuery).filter(k => {
                            const commonKey = payload.extra?.ignoreKeySuffix ? k.split('_')[0] : k;
                            return commonKey === storeKey
                        }).map(k => state.dataQuery[k]);
                    } else {
                        queries = [payload.query]
                    }

                    for (const query of queries) {
                        if (query.useCache && JSON.stringify(query) === JSON.stringify(state.dataQuery[storeKey])) {
                            commit(CrudResponseAction.GetAllNoop, {key: storeKey});
                        } else {
                            const echoId = Guid.NewGuid();
                            query.params = {
                                ...query.params,
                                headers: {
                                    EchoId: echoId
                                }
                            };
                            state.crudStoreEcho.data[storeKey] = echoId;
                            commit(CrudAction.SetEcho, state.crudStoreEcho as any);
                            const result = await crudServiceInstance.getAll(query);
                            if (echoId == state.crudStoreEcho.data[storeKey]) {
                                commit(CrudResponseAction.GetAllSuccess, {
                                    data: (<any>result).data.returnValue.items,
                                    currentPage: (<any>result).data.returnValue.currentPage,
                                    rowCount: (<any>result).data.returnValue.rowCount,
                                    pageSize: (<any>result).data.returnValue.pageSize,
                                    pageCount: (<any>result).data.returnValue.pageCount,
                                    query,
                                    key: storeKey
                                });
                            }
                        }
                    }
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.GetAllFailed, {
                        key: createStoreKey(storeKey),
                        errorMessage: 'Fetch data request has failed.'
                    })
                }
            },
            [CrudAction.Get]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    commit(CrudAction.Get, payload);
                    const echoId = Guid.NewGuid();
                    state.crudStoreEcho.detail[storeKey] = echoId;
                    commit(CrudAction.SetEcho, state.crudStoreEcho as any);
                    const result = await crudServiceInstance.get(payload.id, {
                        headers: {
                            EchoId: echoId
                        }
                    });
                    if (echoId == state.crudStoreEcho.detail[storeKey]) {
                        commit(CrudResponseAction.GetSuccess, {key: storeKey, item: result.data.returnValue});
                    }
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.GetFailed, {
                        errorMessage: 'Fetch item request has failed.',
                        key: storeKey
                    })
                }
            },
            [CrudAction.Create]: async ({commit, state, dispatch}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    commit(CrudAction.Create, payload);

                    let item;
                    if (!payload.extra?.local) {
                        const result = await crudServiceInstance.create(payload.item);
                        item = result.data.returnValue;
                    } else {
                        item = payload.item;
                    }
                    commit(CrudResponseAction.CreateSuccess, {key: storeKey, item, extra: payload.extra});
                    if (!payload.extra?.local && state.dataQuery[storeKey]) {
                        dispatch(CrudAction.GetAll, {key: storeKey, query: state.dataQuery[storeKey]})
                    }
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.CreateFailed, {
                        key: storeKey,
                        errorMessage: 'Create item has failed.'
                    })
                }
            },
            [CrudAction.Update]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    commit(CrudAction.Update, payload);
                    const updatedItem = await crudServiceInstance.update(payload.item);
                    const updatedData = updateStoreData(state.data, updatedItem);
                    commit(CrudResponseAction.UpdateSuccess, {key: storeKey, item: updatedItem, data: updatedData});
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.UpdateFailed, {
                        key: storeKey,
                        errorMessage: 'Update item has failed.'
                    })
                }
            },
            [CrudAction.PartialUpdate]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    commit(CrudAction.PartialUpdate, payload);

                    if (payload.item && payload.diff) {
                        throw new Error('Invalid payload for Batch Partial Update, only one property can be defined (item, diff).')
                    }

                    const detail = state.detail[storeKey];
                    const operations = payload
                        .diff
                        ? payload.diff
                        // eslint-disable-next-line @typescript-eslint/ban-types
                        : compare(detail as Object, payload.item as TDetail);

                    let updatedItem: TDetail | undefined = undefined;
                    if (detail) {
                        updatedItem = payload.extra?.local
                            ? applyPatch<TDetail>(cloneDeep(detail) as TDetail, operations).newDocument
                            : await crudServiceInstance.partialUpdate(payload.id, operations);
                    } else if (payload.extra?.local == undefined || !payload.extra?.local) {
                        updatedItem = await crudServiceInstance.partialUpdate(payload.id, operations);
                    }

                    const updatedData = patchStoreData(state.data, payload.id, operations);
                    commit(CrudResponseAction.PartialUpdateSuccess, {
                        key: storeKey,
                        id: payload.id,
                        item: updatedItem,
                        data: updatedData,
                        extra: payload.extra
                    });
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.PartialUpdateFailed, {
                        key: storeKey,
                        errorMessage: 'Partial Update item has failed.'
                    })
                }
            },
            [CrudAction.Delete]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    commit(CrudAction.Delete, {key: payload.key, id: payload.id});
                    const query: CrudDataQuery<T> = payload.query ? payload.query as CrudDataQuery<T> : createCrudQueryPayload(undefined, [
                        {
                            field: 'id',
                            op: 'eq',
                            comparand: payload.id!
                        }
                    ]).query;

                    let result;
                    if (!payload.extra?.local) {
                        result = await crudServiceInstance.delete(query);
                    } else {
                        result = {
                            items: state.data[storeKey].filter(item => item.id === (payload.id || query.data.predicates.find(p => p.field === 'id')?.comparand))
                        }
                    }

                    commit(CrudResponseAction.DeleteSuccess, <any>{
                        data: (<any>result).items,
                        key: storeKey
                    });
                    //console.log(CrudResponseAction.DeleteSuccessDone, payload);
                    commit(CrudResponseAction.DeleteSuccessDone, payload);
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.DeleteFailed, {
                        key: storeKey,
                        errorMessage: 'Delete item has failed.'
                    })
                }
            },
            [CrudAction.GetCount]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    payload.query.aggregation = AggregationEnum.Count;
                    commit(CrudAction.GetCount, payload);

                    const echoId = Guid.NewGuid();
                    state.crudStoreEcho.count[storeKey] = echoId;
                    commit(CrudAction.SetEcho, state.crudStoreEcho as any);
                    
                    const result = await crudServiceInstance.getAggregation(payload.query, {
                        headers: {
                            EchoId: echoId
                        }
                    });
                    const returnValue = result;
                    if (echoId == state.crudStoreEcho.count[storeKey]) {
                        commit(CrudResponseAction.GetCountSuccess, {
                            key: storeKey, 
                            item: returnValue
                        } as any);
                    }
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.GetCountFailed, {
                        errorMessage: 'Fetch count request has failed.',
                        key: storeKey
                    })
                }
            },
            [CrudAction.GetAvg]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    payload.query.aggregation = AggregationEnum.Avg;
                    commit(CrudAction.GetAvg, payload);
                    
                    const echoId = Guid.NewGuid();
                    state.crudStoreEcho.avg[storeKey] = echoId;
                    commit(CrudAction.SetEcho, state.crudStoreEcho as any);
                    
                    const result = await crudServiceInstance.getAggregation(payload.query, {
                        headers: {
                            EchoId: echoId
                        }
                    });
                    const returnValue = result;
                    if (echoId == state.crudStoreEcho.avg[storeKey]) {
                        commit(CrudResponseAction.GetAvgSuccess, {
                            key: storeKey, 
                            item: returnValue
                        } as any);
                    }
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.GetAvgFailed, {
                        errorMessage: 'Fetch count request has failed.',
                        key: storeKey
                    })
                }
            },
            [CrudAction.GetSum]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    
                    payload.query.aggregation = AggregationEnum.Sum;
                    commit(CrudAction.GetSum, payload);

                    const echoId = Guid.NewGuid();
                    state.crudStoreEcho.sum[storeKey] = echoId;
                    commit(CrudAction.SetEcho, state.crudStoreEcho as any);
                    
                    const result = await crudServiceInstance.getAggregation(payload.query, {
                        headers: {
                            EchoId: echoId
                        }
                    });
                    const returnValue = result;

                    if (echoId == state.crudStoreEcho.sum[storeKey]) {
                        commit(CrudResponseAction.GetSumSuccess, {
                            key: storeKey, 
                            item: returnValue
                        } as any);
                    }
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.GetSumFailed, {
                        errorMessage: 'Fetch count request has failed.',
                        key: storeKey
                    })
                }
            },
            [CrudAction.Download]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    commit(CrudAction.Download, payload);

                    let queries: CrudDataQuery<T>[];
                    if (payload.query === 'same') {
                        queries = Object.keys(state.dataQuery).filter(k => {
                            const commonKey = payload.extra?.ignoreKeySuffix ? k.split('_')[0] : k;
                            return commonKey === storeKey
                        }).map(k => state.dataQuery[k]);
                    } else {
                        queries = [payload.query]
                    }

                    console.log(payload.query, queries);
                    for (const query of queries) {
                        const result = await crudServiceInstance.download(query);
                            commit(CrudResponseAction.DownloadSuccess, {
                                data: (<any>result).data.returnValue,
                                query,
                                key: storeKey
                            });
                    }
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.DownloadFailed, {
                        key: createStoreKey(storeKey),
                        errorMessage: 'Download data request has failed.'
                    })
                }
            },
            [CrudAction.GetGroupped]: async ({commit, state}, payload) => {
                const storeKey = createStoreKey(payload.key);
                try {
                    commit(CrudAction.GetGroupped, payload);

                    const queries: GrouppedQueryModel[] = [payload.query];
                    
                    const echoId = Guid.NewGuid();
                    state.crudStoreEcho.grouppedData[storeKey] = echoId;
                    commit(CrudAction.SetEcho, state.crudStoreEcho as any);
                    
                    for (const query of queries) {
                        const result = await crudServiceInstance.getGroupped(query, {
                            headers: {
                                EchoId: echoId
                            }
                        });
                        if (echoId == state.crudStoreEcho.grouppedData[storeKey]) {
                            commit(CrudResponseAction.GetGrouppedSuccess, {
                                data: (<any>result).data.returnValue,
                                query,
                                key: storeKey
                            });
                        }
                        else {
                            debugger;
                        }
                    }
                } catch (ex) {
                    console.error(ex);
                    commit(CrudResponseAction.GetGrouppedFailed, {
                        key: createStoreKey(storeKey),
                        errorMessage: 'Fetch groupped data request has failed.'
                    })
                }
            },
            ...storeModule.actions
        },
        mutations: {
            [CrudAction.GetAll]: (state, payload) => {
                state.data = {
                    ...state.data,
                    [payload.key!]: []
                };
                setPending(true, state, payload.key);
            },
            [CrudResponseAction.GetAllSuccess]: (state, payload) => {
                state.data = {
                    ...state.data,
                    [payload.key]: payload.data
                };
                state.dataQuery = {
                    ...state.dataQuery,
                    [payload.key]: payload.query
                };
                state.dataStats = {
                    ...state.dataStats,
                    [payload.key]: {
                        currentPage: payload.currentPage,
                        rowCount: payload.rowCount,
                        pageCount: payload.pageCount,
                        pageSize: payload.pageSize
                    } as CrudDataStats
                };
                setPending(false, state, payload.key);
            },
            [CrudResponseAction.GetAllNoop]:
                (state, payload) => {
                    setPending(false, state, payload.key);
                },
            [CrudResponseAction.GetAllFailed]:
                (state, payload) => {
                    setPending(false, state, payload.key);
                    state.error = payload.errorMessage
                },
            [CrudAction.Get]:
                (state, payload) => {
                    state.detail = {
                        ...state.detail,
                        [payload.key]: null
                    }
                    setPending(true, state, payload.key);
                },
            [CrudResponseAction.GetSuccess]:
                (state, payload) => {
                    state.detail = {
                        ...state.detail,
                        [payload.key]: payload.item
                    }
                    setPending(false, state, payload.key);
                },
            [CrudResponseAction.GetFailed]:
                (state, payload) => {
                    setPending(false, state, payload.key);
                    state.error = payload.errorMessage;
                },
            [CrudAction.Create]:
                (state, payload) => {
                    setSaving(true, state, payload.key);
                },
            [CrudResponseAction.CreateSuccess]:
                (state, payload) => {
                    setSaving(false, state, payload.key);

                    state.detail = {
                        ...state.detail,
                        [payload.key]: payload.item,
                        [payload.item.id!]: payload.item
                    }

                    if (payload.extra?.local) {
                        state.data[payload.key] = [
                            ...state.data[payload.key],
                            <any>payload.item
                        ];
                    }
                },
            [CrudResponseAction.CreateFailed]:
                (state, payload) => {
                    state.error = payload.errorMessage;
                    setSaving(false, state, payload.key);
                },
            [CrudAction.Update]:
                (state, payload) => {
                    setSaving(true, state, payload.key);
                },
            [CrudResponseAction.UpdateSuccess]:
                (state, payload) => {
                    state.detail = {
                        ...state.detail,
                        [payload.key]: payload.item
                    }
                    Object.keys(state.data).forEach(key => {
                        const f = state.data[key].find(n => n.id == payload.item.id);
                        if (f) {
                            const items = [...state.data[key]];
                            items.splice(items.indexOf(f), 1, payload.item as any);
                            state.data = {
                                ...state.data,
                                [key]: items
                            };
                        }
                    });
                    /*Object.keys(payload.data).forEach(key => {
                        state.data[key] = payload.data[key];
                    });*/

                    setSaving(false, state, payload.key);
                },
            [CrudResponseAction.UpdateFailed]:
                (state, payload) => {
                    state.error = payload.errorMessage;
                    setSaving(false, state, payload.key);
                },
            [CrudAction.PartialUpdate]:
                (state, payload) => {
                    setSaving(true, state, payload.key);
                },
            [CrudResponseAction.PartialUpdateSuccess]:
                (state, payload) => {
                    setSaving(false, state, payload.key);

                    if (state.detail[payload.key]) {
                        state.detail = {
                            ...state.detail,
                            [payload.key]: payload.item
                        }
                    }
                    Object.keys(payload.data).forEach(key => {
                        state.data[key] = payload.data[key];
                    });
                },
            [CrudResponseAction.PartialUpdateFailed]: (state, payload) => {
                state.error = payload.errorMessage;
                setSaving(false, state, payload.key);
            },
            [CrudAction.GetCount]: (state, payload) => {
                state.count = {
                    ...state.count,
                    [payload.key!]: null
                }
            },
            [CrudResponseAction.GetCountSuccess]: (state, payload) => {
                state.count = {
                    ...state.count,
                    [payload.key!]: payload.item
                }
            },
            [CrudResponseAction.GetCountFailed]:
                (state, payload) => {
                    state.count = {
                        ...state.count,
                        [payload.key!]: null
                    }
                },
            [CrudAction.GetAvg]:
                (state, payload) => {
                    state.avg = {
                        ...state.avg,
                        [payload.key!]: null
                    }
                },
            [CrudResponseAction.GetAvgSuccess]:
                (state, payload) => {
                    state.avg = {
                        ...state.avg,
                        [payload.key!]: payload.item
                    }
                },
            [CrudResponseAction.GetAvgFailed]:
                (state, payload) => {
                    state.avg = {
                        ...state.avg,
                        [payload.key!]: null
                    }
                },
            [CrudAction.GetSum]:
                (state, payload) => {
                    state.sum = {
                        ...state.sum,
                        [payload.key!]: null
                    }
                },
            [CrudResponseAction.GetSumSuccess]:
                (state, payload) => {
                    state.sum = {
                        ...state.sum,
                        [payload.key!]: payload.item
                    }
                },
            [CrudResponseAction.GetSumFailed]:
                (state, payload) => {
                    state.sum = {
                        ...state.sum,
                        [payload.key!]: null
                    }
                },
            [CrudAction.Delete]:
                (state, payload) => {
                    setDeleting(true, state, payload.key);
                },
            [CrudResponseAction.DeleteSuccess]:
                (state, payload) => {
                    // Delete item from data
                    const recordsToDelete = payload.data.map(x => x.id);
                    const storedItemsToDelete = state.data[payload.key].filter(item => recordsToDelete.indexOf(item.id) > -1);
                    for (const x of storedItemsToDelete) {
                        const dataItemIndex = state.data[payload.key].findIndex(item => item.id == x.id);
                        const dataArr = [...state.data[payload.key]];
                        if (dataItemIndex > -1) {
                            dataArr.splice(dataItemIndex, 1);
                            state.data[payload.key] = dataArr;
                        }
                        // Delete item from detail
                        const detail = state.detail[payload.key];
                        if (detail) {
                            state.detail[x.id!] = null;
                        }
                    }
                    setDeleting(false, state, payload.key);
                },
            [CrudResponseAction.DeleteSuccessDone]:
                (state, payload) => {
                    setDeleting(false, state, payload.key);
                },
            [CrudResponseAction.DeleteFailed]:
                (state, payload) => {
                    setDeleting(false, state, payload.key);
                },
            [CrudAction.Download]:
                (state, payload) => {
                    setDownloading(true, state, payload.key);
                },
            [CrudResponseAction.DownloadSuccess]:
                async (state, payload) => {
                    const res = payload.data as FileDownloadModel;
                    const blob = b64toBlob(res.base64!, res.contentType!);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute('download', res.filename!);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode!.removeChild(link);
                    setDownloading(false, state, payload.key);
                },
            [CrudResponseAction.DownloadSuccessDone]:
                (state, payload) => {
                    setDownloading(false, state, payload.key);
                },
            [CrudResponseAction.DownloadFailed]:
                (state, payload) => {
                    setDownloading(false, state, payload.key);
                },
            [CrudAction.GetGroupped]: (state, payload) => {
                state.grouppedData = {
                    ...state.grouppedData,
                    [payload.key!]: []
                };
                setPending(true, state, payload.key);
            },
            [CrudResponseAction.GetGrouppedSuccess]: (state, payload) => {
                state.grouppedData = {
                    ...state.grouppedData,
                    [payload.key]: payload.data
                };
                setPending(false, state, payload.key);
            },
            [CrudResponseAction.GetGrouppedNoop]:
                (state, payload) => {
                    setPending(false, state, payload.key);
                },
            [CrudResponseAction.GetGrouppedFailed]:
                (state, payload) => {
                    setPending(false, state, payload.key);
                    state.error = payload.errorMessage
                },
            [CrudAction.SetEcho]: (state, payload) => {
                    state.crudStoreEcho = {
                        ...state.crudStoreEcho,
                        ...payload
                    };
                },
            ...
                storeModule.mutations
        },
        getters: {
            [CrudGetter.Pending]:
                state => (key?: string) => state.pending[createStoreKey(key)] ?? false,
            [CrudGetter.Saving]:
                state => (key?: string) => state.saving[createStoreKey(key)] ?? false,
            [CrudGetter.Deleting]:
                state => (key?: string) => state.deleting[createStoreKey(key)] ?? false,
            [CrudGetter.Error]:
                state => state.error,
            [CrudGetter.Data]:
                state => (key?: string) => state.data[createStoreKey(key)] ?? [],
            [CrudGetter.DataItem]:
                state => (id: string, key?: string) => state.data[createStoreKey(key)].find(item => item.id === id),
            [CrudGetter.Detail]:
                state => (key?: string) => state.detail[createStoreKey(key)] ?? null,
            [CrudGetter.Downloading]:
                state => (key?: string) => state.downloading[createStoreKey(key)] ?? false,
            [CrudGetter.DataStats]:
                state => (key?: string) => state.dataStats[createStoreKey(key)] ?? new CrudDataStats(),
            [CrudGetter.Count]:
                state => (key?: string) => state.count[createStoreKey(key)] ?? null,
            [CrudGetter.Avg]:
                state => (key?: string) => state.avg[createStoreKey(key)] ?? null,
            [CrudGetter.Sum]:
                state => (key?: string) => state.sum[createStoreKey(key)] ?? null,
            [CrudGetter.GrouppedData]:
                state => (key?: string) => state.grouppedData[createStoreKey(key)] ?? [],
            ...
                storeModule.getters
        }
    };

    store.registerModule(storeName, crudStore);
    return {
        dispatch: createStoreAction(storeName),
        useGetter: createStoreGetter(storeName),
        getActionName: (action) => `${storeName}/${action}`,
        subscribe: (action: CrudAction | CrudResponseAction, callback: (payload: any) => void, key?: string) => {
            const unsub = store.subscribe((mutation, state) => {
                if (mutation.type === `${storeName}/${action}` && (!key || mutation.payload.key === key)) {
                    callback(mutation.payload);
                }
            });

            return unsub;
        }
    }
}
const b64toBlob = (b64Data: string, contentType='', sliceSize=512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
