






























import { PartyModel } from '@/libs/Api';
import { partiesStore } from '@/libs/parties/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { isArray } from 'lodash';
import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import Autocomplete from '../components/Autocomplete.vue';
import { Guid } from '../functions/guid';
import {CrudQueryPredicate} from "@/libs/core/+state/models/crud-query-predicate";

@Component({})
export default class PartySelect extends Vue {
    
    @VModel() innerValue!: string;
    @Prop({ default: "" }) label!: string;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) enableLoadAll!: boolean;
    
    @Ref() autocomplete!: Autocomplete;
    
    key = Guid.NewGuid();
    searchInput = "";
    debounce = null;
    disableFilter = true;
    
    get loading(): boolean {
        return partiesStore.useGetter(CrudGetter.Pending, this.key);
    }
    
    get items(): PartyModel[] {
        let items = partiesStore.useGetter(CrudGetter.Data, this.key) as PartyModel[];
        const detail = partiesStore.useGetter(CrudGetter.Detail, this.key) as PartyModel;
        if (this.innerValue && detail?.id == this.innerValue) {
            items = [
                detail,
                ...items
            ];
        }
        if (items.length > 0 && this.disableFilter) {
            this.disableFilter = false;
        }
        return items;
    }
    
    mounted() {
        this.loadItems();
    }
    
    @Watch("innerValue")
    valueChanged($v: any, $p: any) {
        if ($v == null && $p != null) {
            this.loadItems();
        }
    }
    
    loadItems() {
        
        const queryFilters: CrudQueryPredicate[] = [
            { field: 'accountType', op: 'in', comparand: [ 'Admin', 'Manager', 'Consultant', 'Assistant' ] }
        ];
        const partyFilters: CrudQueryPredicate[] = [];
        const searchFilters: CrudQueryPredicate[] = [];
        if (this.searchInput) {
            const parts = this.searchInput?.split(" ") ?? [];
            for (const p of parts.where(x => (x?.length ?? 0) > 0).toArray()) {
                searchFilters.push({
                    field: "id", op: "wrap", junction: "or",
                    comparand: [
                        { field: "firstname", op: "c", comparand: p.trim(), junction: "or" },
                        { field: "lastname", op: "c", comparand: p.trim(), junction: "or" },
                        { field: "companyName", op: "c", comparand: p.trim(), junction: "or" }
                    ]
                });
            }
        }
        if (searchFilters.length > 0) {
            partyFilters.push({ field: 'id', op: 'wrap', comparand: searchFilters, junction: 'or' });
        }
        if (partyFilters.length > 0) {
            queryFilters.push({ field: "id", op: "wrap", comparand: partyFilters, junction: "and" });
        }
        partiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyModel>([
            {
                field: "lastName",
                index: 0,
                order: "asc"
            }
        ], queryFilters, { pageIndex: 1, pageSize: this.enableLoadAll ? 9999 : 10 }, false, this.key));
        
        if (this.innerValue) {
            partiesStore.dispatch(CrudAction.Get, { id: this.innerValue, key: this.key });
        }
        
    }
    
    inputChanged($e) {
        if (this.searchInput == $e || this.innerValue) {
            this.autocomplete.blur();
            return;
        }
        this.searchInput = $e;
        if (!this.disableFilter) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.loadItems();
            }, 500);
        }
    }
    
}
