export enum BusinessModule {
    NULL = '',
    Codebook = 'Codebook',
    Product = 'Product',
    ProductType = 'ProductType',
    ProductActivity = 'ProductActivity',
    ProductNotificationRule = 'ProductNotificationRule',
    Party = 'Party',
    PartyProduct = 'PartyProduct',
    PartyProductQuotation = 'PartyProductQuotation',
    PartyActivity = 'PartyActivity',
    PartyCredit = 'PartyCredit',
    UserTask = 'UserTask',
    UserTaskActivity = 'UserTaskActivity',
    ApplicationUser = 'ApplicationUser',
    Event = 'Event',
    EventParticipant = 'EventParticipant',
    Notification = 'Notification',
    Transaction = 'Transaction',
    StateOfCapital = 'StateOfCapital',
    StateOfCapitalYear = 'StateOfCapitalYear',
    UpdateLog = 'UpdateLog',
    File = 'File',
    FileActivity = 'FileActivity',
    ExchangeRate = 'ExchangeRate',
    Wallet = 'Wallet',
    WalletTransaction = 'WalletTransaction',
    Currency = 'Currency',
    PartyProductWallet = 'PartyProductWallet',
    ConversionExchangeRate = 'ConversionExchangeRate',
    Trade = 'Trade',
    Share = 'Share',
    ShareLog = 'ShareLog',
}
