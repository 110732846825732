






























































































import { ApplicationUserModel, PartyModel, PartyProductModel, ProductModel, ProductTypeModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { partiesStore, partyProductsStore } from '@/libs/parties/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { productsStore, productTypesStore } from '@/libs/products/+state/store';

import InsuaranceCard from '@/libs/clients/components/InsuaranceCard.vue';
import LoanCard from '@/libs/clients/components/LoanCard.vue';
import InvestmentCard from '@/libs/clients/components/InvestmentCard.vue';
import NOIFCard from '@/libs/clients/components/NOIFCard.vue';
import ReOpportunitiesCard from '@/libs/clients/components/ReOpportunitiesCard.vue';
import NREICard from '@/libs/clients/components/NREICard.vue';
import NCIFCard from '@/libs/clients/components/NCIFCard.vue';
import NWCard from '@/libs/clients/components/NWCard.vue';
import CltCard from '@/libs/clients/components/CltCard.vue';
import CftCard from '@/libs/clients/components/CftCard.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Constants } from '@/libs/constants/constants';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import NNremCard from "@/libs/clients/components/NNremCard.vue";

@Component({
    components: {
	    NNremCard,
        InsuaranceCard,
        LoanCard,
        InvestmentCard,
        NOIFCard,
		ReOpportunitiesCard,
        NREICard,
        NCIFCard,
        NWCard,
        CltCard,
		CftCard
    }
})
export default class ClientProducts extends Vue {

    get client(): PartyModel {
        const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
        return partiesStore.useGetter(CrudGetter.Detail, this.id != null ? this.id : user.partyId!);
    }

    noifPartyProducts: PartyProductModel[] = [];
    inactiveNoifPartyProducts: PartyProductModel[] = [];
    newNoifProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.NoifProductId
    } as PartyProductModel;

	reOpportunitiesPartyProducts: PartyProductModel[] = [];
	inactiveReOpportunitiesPartyProducts: PartyProductModel[] = [];
	newReOpportunitiesProduct = {
		id: Guid.EmptyGuid(),
		productId: Constants.ReOpportunitiesId
	} as PartyProductModel;

    nreiPartyProducts: PartyProductModel[] = [];
    inactiveNreiPartyProducts: PartyProductModel[] = [];
    newNreiProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.NreiProductId
    } as PartyProductModel;

    wealthPartyProducts: PartyProductModel[] = [];
    inactiveWeralthPartyProducts: PartyProductModel[] = [];
    newWealthProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.WealthProductId
    } as PartyProductModel;

    cltPartyProducts: PartyProductModel[] = [];
    inactiveCltPartyProducts: PartyProductModel[] = [];
    newCltProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.CltProductId
    } as PartyProductModel;

    cltCopyPartyProducts: PartyProductModel[] = [];
    inactiveCltCopyPartyProducts: PartyProductModel[] = [];
    newCltCopyProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.CftProductId
    } as PartyProductModel;

	nremPartyProducts: PartyProductModel[] = [];
    inactiveNremPartyProducts: PartyProductModel[] = [];
    newNremProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.NremProductId
    } as PartyProductModel;

    ncifPartyProduct: PartyProductModel | null = null;
    insuranceProducts: PartyProductModel[] = [];
    loanProducts: PartyProductModel[] = [];
    investmentProducts: PartyProductModel[] = [];
    inactiveProducts: PartyProductModel[] = [];

    nfgProductType: ProductTypeModel | undefined = undefined;
    insuranceProductType: ProductTypeModel | undefined = undefined;
    loanProductType: ProductTypeModel | undefined = undefined;
    investmentProductType: ProductTypeModel | undefined = undefined;

    newId: string | null = Guid.EmptyGuid();

    @Prop() id!: string;

    @Watch("id")
    idChanged () {
        this.loadProducts();
    }

    mounted () {
        const productTypes: ProductTypeModel[] = productTypesStore.useGetter(CrudGetter.Data);
        this.nfgProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.NfgProductTypeId; })
        this.insuranceProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.InsuranceProductTypeId; });
        this.loanProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.LoanProductTypeId; });
        this.investmentProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.InvesmentProductTypeId; });
        this.loadProducts();
    }

    loadProducts () {
        productsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductModel>([ { field: "name", index: 1, order: "asc" } ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === productsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.loadPartyProducts();
            }
        });
    }

    loadPartyProducts () {
        const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
        partyProductsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyProductModel>({ field: "createdAt", index: 1, order: "asc" }, [
            { field: "partyId", op: "eq", comparand: this.id != null ? this.id : user.partyId! }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.makeProducts();
            }
        });
    }

    makeProducts () {

        this.loanProducts = [];
        this.investmentProducts = [];
        this.insuranceProducts = [];
        this.inactiveProducts = [];

        this.noifPartyProducts = [];
        this.inactiveNoifPartyProducts = [];

        this.reOpportunitiesPartyProducts = [];
        this.inactiveReOpportunitiesPartyProducts = [];

        this.nreiPartyProducts = [];
        this.inactiveNreiPartyProducts = [];

        this.wealthPartyProducts = [];
        this.inactiveWeralthPartyProducts = [];

        this.cltPartyProducts = [];
        this.inactiveCltPartyProducts = [];

        this.cltCopyPartyProducts = [];
        this.inactiveCltCopyPartyProducts = [];

        this.nremPartyProducts = [];
        this.inactiveNremPartyProducts = [];

        this.$nextTick(() => {
            
            const products = productsStore.useGetter(CrudGetter.Data) as Array<ProductModel>;
            const partyProducts = partyProductsStore.useGetter(CrudGetter.Data) as Array<PartyProductModel>;

            const activeInsurancePartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.InsuranceProductTypeId) && x.isActive == true);
            const newInsuranceProducts = products.filter((e: ProductModel) => { return e.productTypeId == this.insuranceProductType?.id; }).map((e: ProductModel) => {
                return this.getPartyProduct(e, []);
            }).filter((e: any) => { return typeof e.isActive == "undefined"; });
            this.insuranceProducts = activeInsurancePartyProducts.concat(newInsuranceProducts);

            const activeLoanPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.LoanProductTypeId) && x.isActive == true);
            const newLoanProducts = products.filter((e: ProductModel) => { return e.productTypeId == this.loanProductType?.id; }).map((e: ProductModel) => {
                return this.getPartyProduct(e, []);
            }).filter((e: any) => { return typeof e.isActive == "undefined" });
            this.loanProducts = activeLoanPartyProducts.concat(newLoanProducts);

            const activeInvestmentPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.InvesmentProductTypeId) && x.isActive == true);
            const newInvestmentProducts = products.filter((e: ProductModel) => { return e.productTypeId == this.investmentProductType?.id; }).map((e: ProductModel) => {
                return this.getPartyProduct(e, []);
            }).filter((e: any) => { return typeof e.isActive == "undefined" });
            this.investmentProducts = activeInvestmentPartyProducts.concat(newInvestmentProducts);

            this.noifPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NoifProductId.toLowerCase()) && x.isActive == true);
            this.inactiveNoifPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NoifProductId.toLowerCase()) && x.isActive == false);

            this.reOpportunitiesPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.ReOpportunitiesId.toLowerCase()) && x.isActive == true);
            this.inactiveReOpportunitiesPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.ReOpportunitiesId.toLowerCase()) && x.isActive == false);

            this.nreiPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NreiProductId.toLowerCase()) && x.isActive == true);
            this.inactiveNreiPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NreiProductId.toLowerCase()) && x.isActive == false);

            this.wealthPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.WealthProductId.toLowerCase()) && x.isActive == true);
            this.inactiveWeralthPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.WealthProductId.toLowerCase()) && x.isActive == false);

            this.cltPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.CltProductId.toLowerCase()) && x.isActive == true);
            this.inactiveCltPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.CltProductId.toLowerCase()) && x.isActive == false);

            this.cltCopyPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.CftProductId.toLowerCase()) && x.isActive == true);
            this.inactiveCltCopyPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.CftProductId.toLowerCase()) && x.isActive == false);

            this.nremPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NremProductId.toLowerCase()) && x.isActive == true);
            this.inactiveNremPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NremProductId.toLowerCase()) && x.isActive == false);

            const inactiveInsuranceProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.InsuranceProductTypeId) && x.isActive == false);
            const inactiveLoanProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.LoanProductTypeId) && x.isActive == false);
            const inactiveInvestmentProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.InvesmentProductTypeId) && x.isActive == false);

            this.inactiveProducts = inactiveInsuranceProducts.concat(inactiveLoanProducts).concat(inactiveInvestmentProducts);

        });

    }

    getPartyProduct (product: ProductModel, partyProducts: PartyProductModel[]) {
        const partyProduct = partyProducts.find((e: PartyProductModel) => { return e.productId == product.id; });
        return partyProduct != null ? partyProduct : {
            id: Guid.EmptyGuid(),
            productId: product.id,
            name: product.name
        } as PartyProductModel;
    }

}
