



















































































































































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { PartyModel, PartyProductModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { partiesStore, partyProductsStore } from '@/libs/parties/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import LoanTypeSelect from '@/libs/common/components-smart/LoanTypeSelect.vue';
import moment from 'moment';

@Component({
    components: {
        LoanTypeSelect
    }
})
export default class InsuarenceDialog extends Vue {

  dialog = false;
  innerValue: string | null = null;
  partyProduct: PartyProductModel | null = null;
  valid = true;
  basicRules = [
    (v: any) => !!v || 'Toto pole je povinné.'
  ];
  @VModel() item!: string;
  @Prop() clientId!: string;
  @Prop() productId!: string;
  @Prop() name!: string;
  @Prop() activator!: string;
  @Ref() form!: any;

  @Watch("dialog")
  dialogChanged(v: boolean) {
    if (!v)
        this.close();
    else
        this.load();
  }

  get client() : PartyModel {
      return partiesStore.useGetter(CrudGetter.Detail, this.clientId!);
  }

  @Watch("item")
  itemChanged (v: string) {
    this.innerValue = v;
  }

  get isNewItem(): boolean {
    return this.innerValue == Guid.EmptyGuid();
  }

  get saving(): boolean {
    return partyProductsStore.useGetter(CrudGetter.Saving);
  }

  get canDelete(): boolean {
    return true;
  }

  mounted () {
    if (this.activator) {
        this.dialog = false;
        this.innerValue = this.item;
    }
    else {
        this.load();
    }
  }

  open () {
      this.dialog = true;
      this.partyProduct = null;
      this.load();
  }

  load() {
    if (this.item == null || this.item == Guid.EmptyGuid()) {
        this.partyProduct = {
            id: Guid.EmptyGuid(),
            name: this.name,
            partyId: this.clientId,
            productId: this.productId,
            dateOfStart: moment().format("YYYY-MM-DDT00:00:00"),
            dateOfSignature: moment().format("YYYY-MM-DDT00:00:00"),
            dateOfEnding: null,
            insurancePrice: null,
            description: null,
            depositAmount: null,
            monthlyRevenue: null,
            revenueAmount: null,
            definedRevenue: null,
            currentCapitalAmount: null,
            pickAmount: null,
            isActive: true,
            bankCompanyId: null,
            projectAddress: null,
            sources: null,
            monthlyContribution: null,
            interestRate: null,
            rpsn: null
        } as PartyProductModel;
    }
    else {
        partyProductsStore.dispatch(CrudAction.Get, { id: this.item, key: this.item });
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetSuccess)) {
                subscribtion();
                self.partyProduct = mutation.payload.item;
            }
        });
    }
  }

  save () {
    if (this.form.validate()) {
        if (this.item == null || this.innerValue == Guid.EmptyGuid()) {
        partyProductsStore.dispatch(CrudAction.Create, { item: this.partyProduct });
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.CreateSuccess)) {
                subscribtion();
                self.close();
                this.$emit("changed");
            }
            else if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.CreateFailed)) {
                subscribtion();
            }
        });
        }
        else {
        partyProductsStore.dispatch(CrudAction.Update, { item: this.partyProduct });
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                subscribtion();
                self.close();
                this.$emit("changed");
            }
            else if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.UpdateFailed)) {
                subscribtion();
                self.close();
            }
        });
        }
    }
  }

  deletePartyProduct () {
    partyProductsStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [ { field: "id", op: "eq", comparand: this.partyProduct!.id! } ]));
    const self = this;
    const subscribtion = this.$store.subscribe((mutation, _state) => {
      if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.DeleteSuccess)) {
          subscribtion();
          self.close();
          this.$emit("changed");
      }
      else if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.DeleteFailed)) {
          subscribtion();
      }
    });
  }

  close () {
    this.dialog = false;
  }

}
