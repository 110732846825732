









import { CodebookModel, CodebookType } from '@/libs/Api';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Guid } from '@/libs/common/functions/guid';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

@Component({ })
export default class CodebookTable extends Vue {

    headers = [
        { text: '#', align: 'left', sortable: false, value: 'position', readonly: true, width: 80, required: false, type: "text" },
        { text: 'Název', align: 'left', sortable: false, value: 'name', readonly: false, required: true, type: "text" }
    ];

    @Prop() name!: string;
    @Prop() type!: string;
    @Prop({ default: null }) productId!: string | null;
    @Prop({ default: false }) color!: boolean;
    @Prop({ default: false }) defaultValue!: boolean;

    get pending(): boolean {
        return codebooksStore.useGetter(CrudGetter.Pending, this.type);
    }

    get saving(): boolean {
        return codebooksStore.useGetter(CrudGetter.Saving, this.type);
    }

    get componentItems(): Array<CodebookModel> {
		let items = codebooksStore.useGetter(CrudGetter.Data, this.type) as CodebookModel[];
		if (this.productId != null && this.productId != Guid.EmptyGuid()) {
			items = items.where(x => x.productId?.toLowerCase() == this.productId?.toLowerCase()).toArray();
		}
        return items
                ?.sort((a: CodebookModel, b: CodebookModel) => { return a.position! - b.position!; });
    }

    mounted () {
        codebooksStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CodebookModel>({
            field: 'position',
            order: 'asc'
        }, [
            {
                field: "type",
                op: "eq",
                comparand: this.type
            }
        ], undefined, false, this.type));
        if (this.color) {
            this.headers.push({ text: 'Barva', align: 'left', sortable: false, value: 'color', readonly: false, required: true, type: "color" });
        }
        if (this.defaultValue) {
            this.headers.push({ text: 'Výchozí', align: 'left', sortable: false, value: 'defaultValue', readonly: false, required: true, type: "boolean" });
        }
    }

    beforeInsert (e: any) {
		debugger;
        e.position = this.componentItems.length + 1;
        const newCodebook: CodebookModel = {
            id: Guid.EmptyGuid(),
            position: e.position,
            name: e.name,
            type: CodebookType[this.type as keyof typeof CodebookType],
			productId: this.productId != null && this.productId != Guid.EmptyGuid() ? this.productId : null
        };
        codebooksStore.dispatch(CrudAction.Create, { item: newCodebook, key: this.type })
        return e;
    }

    saveItem (e: CodebookModel) {
		if (e.id != undefined) {
			codebooksStore.dispatch(CrudAction.Update, { item: e, key: this.type });
		}
    }

    deleteItem (e: CodebookModel) {
        codebooksStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [
            { field: "id", op: "eq", comparand: e.id! }
        ], undefined, false, this.type));
    }

}
