













































































































































import { ApplicationUserModel, PartyModel } from '@/libs/Api';
import {Component, Vue, Prop} from 'vue-property-decorator';
import { partiesStore } from '@/libs/parties/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import MaritalStatusSelect from '@/libs/common/components-smart/MaritalStatusSelect.vue';
import { applicationUserStore } from '@/libs/user-settings/+state/store';

@Component({
    components: {
        MaritalStatusSelect
    }
})
export default class AccountGeneral extends Vue {

    id = (applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel).partyId!;
    valid = false;
    basicRules = [];

    get client(): PartyModel {
        return partiesStore.useGetter(CrudGetter.Detail, this.id);
    }

}
