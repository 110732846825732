
























































































































































































































































































































































































import {
	ExchangeRateModel,
	ExchangeRateTypeEnum,
	GroupByProjectionDefinition,
	ProjectionAggregationEnum,
	QueryOperator,
	SortOrder,
	TradeModel,
	TradeTypeEnum,
	TransactionModel,
	WalletModel,
	WalletTransactionModel
} from '@/libs/Api';
import {Component, Ref, Watch} from 'vue-property-decorator';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload, createGrouppedQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';

import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Constants} from '@/libs/constants/constants';
import Page from '@/Page.vue';
import {tradesStore} from '@/libs/trades/+state/store';
import ApiService from '@/libs/core/api-service';
import {cloneDeep, head} from 'lodash';
import moment from 'moment';
import {exchangeRatesStore} from '@/libs/exchange-rates/+state/store';
import {CrudDataStats} from '@/libs/core/+state/models/crud-data-stats';

@Component({
    computed: {
        moment() {
            return moment
        }
    },
    methods: {head},
    components: {
        CrudTable
    }
})
export default class ProductNremTrades extends Page {

    headers = [
        { text: 'Klient', align: 'left', sortable: false, value: 'partyProduct.party.displayName' },
        { text: 'Typ', align: 'left', sortable: false, value: 'type' },
        { text: 'Počet CP', align: 'left', sortable: false, value: 'fromAmount' },
        { text: 'Částka', align: 'left', sortable: false, value: 'toAmount' },
        { text: 'Kurz IM4', align: 'left', sortable: false, value: 'exchangeRateIm4' },
        { text: 'Datum', align: 'left', sortable: false, value: 'time' },
        { text: 'Zadáno uživatelem', align: 'left', sortable: false, value: 'createdBy.displayName' },
        { text: '', align: 'left', sortable: false, value: 'id' },
    ];

    clientId: string | null = null;
    since: string | null = null;
    until: string | null = null;
    showFilters = false;
    productId = Constants.NremProductId;
    isBuy = false;
    partyProductId = null;
    amount = null;
    onlyDeposit = 0;
    im4ExchangeRate: ExchangeRateModel = null;
	pageIm4ExchangeRate: ExchangeRateModel = null;
    buyDialog = false;
    sellDialog = false;
    deleteDialog = false;
    buyDialogIsValid = false;
    sellDialogIsValid = false;
    saving = false;
    trade: TradeModel;
    wallets: WalletModel[];
    partyWalletCzk: WalletModel = null;
    partyWalletIm4: WalletModel = null;
    basicRules = [
        (v: any) => !!v || 'Toto pole je povinné.'
    ];
	customTime = false;
	tradeInitializating = false;
	im4ExchangeRateNotLoaded = false;
	tradeTime: string | null = null;
	tradeMaxTime: string | null = null;
	totalFreeShares = 0;
	computedBoughtShares = 0;

	pageIndex = 1;
	pageSize = 50;

	@Ref() crudTable!: CrudTable;
    @Ref() buyForm!: any;
    @Ref() sellForm!: any;

    @Watch("clientId")
    @Watch("since")
    @Watch("until")
    @Watch("onlyDeposit")
    filtersChanged(): void {
        this.loadData();
    }

	@Watch("pageIndex")
	@Watch("pageSize")
	paginationChanged() {
		this.loadData();
	}

	@Watch("partyProductId")
    partyProductIdChanged(v: string | null, p: string | null) {
        if (v != null && v != p) {
            this.getWallets();
        }
    }

    get newId(): string {
        return Guid.EmptyGuid();
    }

    get items(): TradeModel[] {
        return tradesStore.useGetter(CrudGetter.Data);
    }
    
    get walletSum(): number {
        const trades = tradesStore.useGetter(CrudGetter.Data) as TradeModel[];
        return trades.select(x => x.toAmount).sum();
    }

    get computedSoldSharesInCzk(): number | null {
        if (this.im4ExchangeRate == null) {
            return null;
        }
        return Number((this.amount * this.im4ExchangeRate.secondaryRate).toFixed(3));
    }

    get grouppedDataDeposit(): any {
        const data = tradesStore.useGetter(CrudGetter.GrouppedData, 'AmountDeposit');
        return data && data?.length > 0 ? data[0] : null;
    }

    get grouppedDataWithdrawal(): any {
        const data = tradesStore.useGetter(CrudGetter.GrouppedData, 'AmountWithdrawal');
        return data && data?.length > 0 ? data[0] : null;
    }

	get currentTotalTokenValueInCzk(): number | null {
		const deposit = tradesStore.useGetter(CrudGetter.GrouppedData, 'AmountDeposit');
		const withdrawal = tradesStore.useGetter(CrudGetter.GrouppedData, 'AmountWithdrawal');
		if (deposit != null && withdrawal != null && this.pageIm4ExchangeRate != null) {
			const currentTokens = deposit[0].amount - withdrawal[0].amount;
			return currentTokens * this.pageIm4ExchangeRate.rate;
		}
		return null;
	}

	get isFutureTradeTime(): boolean {
		debugger;
		if (this.tradeTime == null) {
			return false;
		}
		return moment(this.tradeTime).isAfter(moment());
	}

	isTokenAmountDecimal(amount: any): boolean {
		return !isNaN(amount) && Number(amount) % 1 != 0;
	}

	get totalRows (): number {
		return ({...tradesStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
	}

    mounted(): void {
        this.loadData();
		this.getCurrentIm4Rate(true);
		this.getExchangeRates();

    }

    loadData(): void {
        let filters: CrudQueryPredicate[] = [
            { field: "partyProduct.productId", op: "eq", comparand: Constants.NremProductId }
        ];
        filters = filters.concat(this.getFilters());
        tradesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TradeModel>([ { field: "time", index: 1, order: "desc" } ], filters, { pageIndex: this.pageIndex, pageSize: this.pageSize }));
        this.loadGrouppedData();
    }

    getFilters(): CrudQueryPredicate[] {
      let filters: CrudQueryPredicate[] = [];
      if (this.clientId) {
        filters.push({ field: "partyProduct.partyId", op: "eq", comparand: this.clientId });
      }
      if (this.since) {
        filters.push({ field: "time", op: "gte", comparand: this.since });
      }
      if (this.until) {
        filters.push({ field: "time", op: "lte", comparand: this.until });
      }
      if (this.onlyDeposit == 1) {
        filters.push({ field: "fromCurrencyId", op: 'eq', comparand: Constants.CurrencyCzkId });
        filters.push({ field: "toCurrencyId", op: 'eq', comparand: Constants.CurrencyNremId });
      }
      else if (this.onlyDeposit == 2) {
        filters.push({ field: "fromCurrencyId", op: 'eq', comparand: Constants.CurrencyNremId });
        filters.push({ field: "toCurrencyId", op: 'eq', comparand: Constants.CurrencyCzkId });
      }
      return filters;
    }
    
    buyClick(): void {
        this.trade = {
            partyProductId: null,
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
            fromAmount: null,
            toAmount: null
        } as TradeModel;
		this.tradeTime = this.trade.time;
        this.partyProductId = null;
        this.amount = 0;
        this.buyDialog = true;
        this.partyWalletCzk = null;
        this.partyWalletIm4 = null;
		this.customTime = false;
		this.tradeInitializating = true;
		this.tradeMaxTime = moment().format("YYYY-MM-DD 23:59:59");
		this.$nextTick(() => {
			this.tradeInitializating = false;
			this.getCurrentIm4Rate();
			this.getTotalFreeShares();
		});
    }
    
    sellClick(): void {
        this.trade = {
            partyProductId: null,
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
            fromAmount: null,
            toAmount: null
        } as TradeModel;
		this.tradeTime = this.trade.time;
        this.partyProductId = null;
        this.amount = 0;
        this.sellDialog = true;
        this.partyWalletCzk = null;
        this.partyWalletIm4 = null;
		this.customTime = false;
		this.tradeInitializating = true;
		this.tradeMaxTime = moment().format("YYYY-MM-DD 23:59:59");
		this.$nextTick(() => {
			this.tradeInitializating = false;
			this.getCurrentIm4Rate();
		});
    }
    
    deleteClick(item: TradeModel) {
        this.deleteDialog = true;
        this.trade = item;
    }

	computeBoughtShares() {
		if (this.im4ExchangeRate == null) {
			return null;
		}
		this.computedBoughtShares = Number((this.amount / this.im4ExchangeRate.secondaryRate).toFixed(3));
	}

	calculateAmountFromBoughtShares() {
		if (this.computedBoughtShares && this.im4ExchangeRate != null && this.im4ExchangeRate.secondaryRate != null) {
			this.amount = this.computedBoughtShares * this.im4ExchangeRate.secondaryRate;
		}
		else {
			this.amount = null;
		}
	}

    saveIm4ExchangeRate(): Promise<ExchangeRateModel> {
        return new Promise<ExchangeRateModel>((resolve, reject) => {
            if (this.im4ExchangeRate.id != Guid.EmptyGuid()) {
                resolve(this.im4ExchangeRate);
            }
            else {
                exchangeRatesStore.dispatch(CrudAction.Create, { item: {
                    fromDate: this.tradeTime,
                    toDate: this.tradeTime,
                    productId: Constants.NremProductId,
                    rate: this.im4ExchangeRate.secondaryRate,
                    type: ExchangeRateTypeEnum.Product,
                    isPublic: false
                } as ExchangeRateModel });
                this.subscribe(exchangeRatesStore, CrudReponse.Create).then((e: ExchangeRateModel) => {
                    this.im4ExchangeRate = e;
                    resolve(e);
                }).catch((e) => reject(e));
            }
        });
    }

    saveItem(): void {
		this.trade.time = this.tradeTime;
        const e = cloneDeep(this.trade);
        if (e.id == null || e.id == Guid.EmptyGuid()) {
	        this.saveIm4ExchangeRate().then(() => {
		        if (this.buyDialog) {
			        if (this.buyForm.validate()) {
				        e.type = TradeTypeEnum.Buy;
				        e.partyProductId = this.partyProductId;
				        e.fromAmount = this.amount;
				        e.toAmount = Number((this.amount / this.im4ExchangeRate.secondaryRate).toFixed(3));
				        e.fromCurrencyId = Constants.CurrencyCzkId;
				        e.toCurrencyId = Constants.CurrencyNremId;
				        e.tradeConversions = [
					        {
						        exchangeRateId: this.im4ExchangeRate.id,
						        fromAmount: this.amount,
						        toAmount: this.amount / this.im4ExchangeRate.secondaryRate
					        }
				        ];
				        this.saving = true;
				        tradesStore.dispatch(CrudAction.Create, { item: e });
				        this.subscribe(tradesStore, CrudReponse.Create).then(() => {
					        this.buyDialog = false;
					        this.sellDialog = false;
					        this.saving = false;
					        this.loadData();
				        })
			        }
		        }
		        else if (this.sellDialog) {
			        if (this.sellForm.validate()) {
				        e.type = TradeTypeEnum.Sell;
				        e.partyProductId = this.partyProductId;
				        e.fromAmount = this.amount;
				        e.toAmount = Number((this.amount * this.im4ExchangeRate.secondaryRate).toFixed(3));
				        e.fromCurrencyId = Constants.CurrencyNremId;
				        e.toCurrencyId = Constants.CurrencyCzkId;
				        e.tradeConversions = [
					        {
						        exchangeRateId: this.im4ExchangeRate.id,
						        fromAmount: this.amount,
						        toAmount: this.amount * this.im4ExchangeRate.secondaryRate
					        }
				        ];
				        this.saving = true;
				        tradesStore.dispatch(CrudAction.Create, { item: e });
				        this.subscribe(tradesStore, CrudReponse.Create).then(() => {
					        this.buyDialog = false;
					        this.sellDialog = false;
					        this.saving = false;
					        this.loadData();
				        });
			        }
		        }
	        });
        }
    }
    
    removeItem(): void {
        this.saving = true;
        tradesStore.dispatch(CrudAction.Delete, createCrudQueryPayload<TransactionModel>(undefined, [ { field: "id", op: "eq", comparand: this.trade.id! } ]));
        this.subscribe(tradesStore, CrudReponse.Delete).then(() => {
            this.saving = false;
            this.deleteDialog = false;
            this.loadData();
        }).catch(ex => {
            this.saving = false;
            console.log(ex);
        })
    }
    
    beforeAdd(e: WalletTransactionModel): void {
        this.amount = 0;
        this.partyProductId = null;
    }

    beforeEdit(e: WalletTransactionModel): void {
        this.isBuy = e.amount >= 0;
        this.amount = Math.abs(e.amount);
        this.partyProductId = e.wallet.partyProductId;
    }

    getCurrentIm4Rate(firstLoad = false): Promise<number | null> {
        return new Promise<number | null>((resolve, reject) => {
			const predicates = [
				{ field: 'productId', op: QueryOperator.Eq, comparand: Constants.NremProductId },
				{ field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Product },
				{ field: 'isPublic', op: QueryOperator.Eq, comparand: true }
			];
			if (this.customTime) {
				predicates.push({ field: 'fromDate', op: QueryOperator.Lte, comparand: this.tradeTime });
				predicates.push({ field: 'toDate', op: QueryOperator.Gte, comparand: this.tradeTime });
			}
            ApiService.api.getExchangeRateBatch(1, 1, {
                predicates: predicates,
                orderby: [
                    { field: "toDate", index: 1, order: SortOrder.Desc }
                ]
            }).then((e: any) => {
                this.im4ExchangeRate = e.data.returnValue.items.firstOrDefault();
				if (this.im4ExchangeRate == null) {
					this.im4ExchangeRateNotLoaded = true;
					this.im4ExchangeRate = {
						id: Guid.EmptyGuid(),
						rate: null
					};
				}
				else {
					this.im4ExchangeRateNotLoaded = false;
				}
				if (firstLoad) {
					this.pageIm4ExchangeRate = {...this.im4ExchangeRate}
				}
				resolve(this.im4ExchangeRate.secondaryRate ?? null);
            }).catch((e: any) => reject(e));
        });
    }

	getExchangeRates() {
		exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([ { field: "fromDate", index: 1, order: "desc" } ], [ { field: "productId", op: "eq", comparand: Constants.NremProductId } ], undefined, false, Constants.NremProductId));
	}

	getTotalFreeShares(): Promise<number | null> {
		return new Promise<number | null>((resolve, reject) => {
			ApiService.api.getShareGroupped({
				predicates: [
					{ field: 'productId', op: QueryOperator.Eq, comparand: Constants.NremProductId },
					{ field: 'ownerId', op: QueryOperator.Eq, comparand: null }
				],
				groupby: [],
				projections: [
					{
						field: "id",
						aggregation: ProjectionAggregationEnum.Count,
						outputField: "count"
					} as GroupByProjectionDefinition
				]
			}).then((e) => {
				this.totalFreeShares = e.data.returnValue.firstOrDefault()?.count;
				resolve(this.totalFreeShares);
			}).catch((e: any) => reject(e));
		});
	}

    getWallets(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            ApiService.api.getWalletBatch(1, 99, {
                predicates: [
                    { field: 'partyProductId', op: QueryOperator.Eq, comparand: this.partyProductId }
                ]
            }).then((e: any) => {
                this.wallets = e.data.returnValue.items;
                this.partyWalletCzk = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId && x.partyProductId == this.partyProductId);
                this.partyWalletIm4 = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyNremId && x.partyProductId == this.partyProductId);
                resolve(this.wallets);
            }).catch((e: any) => reject(e));
        });
    }

    getIm4RateOfTrade(item: TradeModel): number | null {
        return item.tradeConversions.firstOrDefault(x => x.exchangeRate.type == ExchangeRateTypeEnum.Product)?.exchangeRate.secondaryRate ?? null;
    }
    
    loadGrouppedData(): void {
        tradesStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<TradeModel>([
            { field: 'fromCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
            { field: 'toCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyNremId }
        ].concat(this.getFilters() as any), [], [
            {
                field: "toAmount",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "amount"
            } as GroupByProjectionDefinition
        ], 'AmountDeposit'));
        tradesStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<TradeModel>([
            { field: 'fromCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyNremId },
            { field: 'toCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId }
        ].concat(this.getFilters() as any), [], [
            {
                field: "fromAmount",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "amount"
            } as GroupByProjectionDefinition
        ], 'AmountWithdrawal'));
    }

	tradeTimeChanged(): void {
		if (!this.tradeInitializating) {
			this.customTime = true;
			this.getCurrentIm4Rate();
		}
	}

	hasCorrectExchangeRate(trade: TradeModel): boolean {
		const exchangeRates = exchangeRatesStore.useGetter(CrudGetter.Data, Constants.NremProductId) as ExchangeRateModel[];
		if (exchangeRates && exchangeRates.any()) {
			const exchangeRate = exchangeRates.where(x => Date.parse(x.fromDate) <= Date.parse(trade.time) && Date.parse(x.toDate) >= Date.parse(trade.time)).firstOrDefault();
			if (exchangeRate) {
				return exchangeRate.secondaryRate == this.getIm4RateOfTrade(trade);
			}
		}
		return true;
	}

	getValidExchangeRate(trade: TradeModel): number {
		const exchangeRates = exchangeRatesStore.useGetter(CrudGetter.Data, Constants.NremProductId) as ExchangeRateModel[];
		if (exchangeRates && exchangeRates.any()) {
			const exchangeRate = exchangeRates.where(x => Date.parse(x.fromDate) <= Date.parse(trade.time) && Date.parse(x.toDate) >= Date.parse(trade.time)).firstOrDefault();
			if (exchangeRate && exchangeRate.secondaryRate) {
				return exchangeRate.secondaryRate;
			}
		}
		return 0;
	}

}
