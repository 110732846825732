



































import { Component, Vue, Watch } from 'vue-property-decorator';

import {authStore} from '@/libs/auth/+store/store';
import { AuthAction, AuthGetter } from '@/libs/auth/models/auth-state';
import { SignInModel } from '@/libs/auth/models/auth-models';

@Component({ })
export default class SignIn extends Vue {
    email = null;
    password = null;
    pending = false;
    rules = {
        username: {
            required: true, 
            email: [true, "Zadejte prosím e-mail v platném tvaru."]
        },
        password: {required: true},
    };
    showToastSuccess = false;
    showToastError = false;
    sending: boolean | null = null;
    error: string | null = null;

    get isLoggedIn(): boolean {
        debugger;
        return authStore.useGetter(AuthGetter.GetIsLoggedIn);
    }
    
    @Watch("showToastSuccess")
    showToastSuccessChanged(v: boolean, p: boolean) {
        console.log(v, p);
    }
    
    @Watch("isLoggedIn")
    isLoggedInChanged (v: boolean) {
        if (v) {
            const self = this;
            authStore.dispatch(AuthAction.UserInfo);
            const subscription = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === authStore.getActionName(AuthAction.UserInfo)) {
                    subscription();
                    const user = mutation.payload;
                    (window as any).user = user;
                    self.$router.replace({ name: "Dashboard" });
                }
            });
        }
        console.log("isLoggedIn", v);
    }

    mounted() {
        if (authStore.useGetter(AuthGetter.GetIsLoggedIn)) {
            this.$router.replace({ name: "Dashboard" });
            return;
        } else {
            if (typeof this.$route.query.recoverySuccess != "undefined" && this.$route.query.recoverySuccess != null) {
                if (this.$route.query.recoverySuccess == "true" || this.$route.query.recoverySuccess) {
                    this.showToastSuccess = true;
                } else {
                    this.showToastError = true;
                }
            }
        }
    }

    submit () {
        let formStatus = true;
        if (formStatus) {
            const payload = new SignInModel();
            payload.email = this.email;
            payload.password = this.password;
            this.sending = true;
            this.error = null;
            authStore.dispatch(AuthAction.SignIn, payload);
            const self = this;
            const createdSubscriber = this.$store.subscribe((mutation, _state) => {
                if(mutation.type === authStore.getActionName(AuthAction.SignInResponseSuccess)) {
                    createdSubscriber();
                    self.sending = false;
                }
                else if(mutation.type === authStore.getActionName(AuthAction.SignInResponseFailed)) {
                    createdSubscriber();
                    self.sending = false;
                    self.error = "Nebylo možné se přihlásit. Prosím zkontrolujte přihlašovací údaje.";
                }
            });
        }
    }

    goToSignUp () {
        this.$router.replace({ name: "SignUp" });
    }

}
