










































































import { PartyModel, ApplicationUserModel, ProductModel } from '@/libs/Api';
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { partiesStore } from '@/libs/parties/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { productsStore } from '@/libs/products/+state/store';
import { Constants } from '@/libs/constants/constants';

@Component
export default class Account extends Vue {

    tab = "General";
    accountPartyId = (applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel).partyId!;

    showCreditMessage = false;
    
    get client(): PartyModel {
        const partyModel = partiesStore.useGetter(CrudGetter.Detail, this.accountPartyId) as PartyModel;
        if (partyModel != null && partyModel.isCreditMessageShown != true) {
            this.showCreditMessage = true;
        }
        return partyModel;
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get creditExchangeRate() {
        const creditProduct = productsStore.useGetter(CrudGetter.Detail, Constants.CreditProductId) as ProductModel;
        return creditProduct?.exchangeRate;
    }

    get timestamp() {
        return new Date().getTime().toString();
    }

    mounted () {
        this.loadClient();
        this.getCreditProduct();
    }

    loadClient () {
        partiesStore.dispatch(CrudAction.Get, { id: this.accountPartyId, key: this.accountPartyId });
    }

    setTab (value: string) {
        this.tab = value;
    }

    creditMessageConfirmed () {
        const partyModel = {...partiesStore.useGetter(CrudGetter.Detail, this.accountPartyId)} as PartyModel;
        partyModel.isCreditMessageShown = true;
        partiesStore.dispatch(CrudAction.Update, { item: partyModel, key: this.accountPartyId });
        this.showCreditMessage = false;
    }
    
    computeCreditPrice (creditValue: number | null, exchangeRate: number | null) {
        if (creditValue != null && exchangeRate != null) {
            return parseFloat((creditValue * exchangeRate).toFixed(2));
        }
        return "--";
    }

    getCreditProduct () {
        productsStore.dispatch(CrudAction.Get, { id: Constants.CreditProductId, key: Constants.CreditProductId });
    }
    
}
